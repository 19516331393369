.row {
  width: 100%; }

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start; }

.no-padding {
  padding: 0; }

.toast-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .toast-wrapper.active {
    height: 4rem;
    opacity: 1; }
  .toast-wrapper.toast-wrapper-large {
    left: 0; }
  .toast-wrapper .toast-message {
    color: white;
    padding: 0 0.125rem 0 1rem;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .toast-wrapper .toast-message .toast-message-text-wrapper {
      display: flex;
      align-items: center; }
      .toast-wrapper .toast-message .toast-message-text-wrapper .toast-message-text {
        flex: 1; }
      .toast-wrapper .toast-message .toast-message-text-wrapper .close-button {
        width: 3rem;
        text-align: center;
        color: white; }
        .toast-wrapper .toast-message .toast-message-text-wrapper .close-button:hover {
          color: rgba(255, 255, 255, 0.75); }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Verlag Book', Arial, Helvetica, sans-serif !important;
  height: 100%; }

@media (max-width: 640px) {
  html,
  body {
    font-size: 13px; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold; }

p {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif; }

.page-padding-bottom {
  padding-bottom: 5rem; }

#root {
  height: 100%; }
